.settings-bg-main {
  height: calc(100vh - 210px);
  width: 100%;
  max-width: 768px;
  overflow-x: auto;
  // margin: 20px;
  background: linear-gradient(
    to bottom,
    rgb(88, 88, 88) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  padding: 60px 100px;
  position: relative;
  border: 2px solid grey;
  @media (max-width:768px) {
    width: auto;
    height: 500px;
  }
  @media (min-width: 500px) and (max-width: 768px) {
    padding: 41px 41px;
    .settings-bg {
      .container {
        .title {
          font-size: 24px;
          margin-top: 50px;
          margin-left: 5px;
        }
        .conditional {
          margin-top: 10px;
          margin-left: 5px;

          font-size: 11px;
        }
        .input-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          margin-top: 33px;
        }
        .input-label {
          font-size: 18px;
        }
      }
    }
    .button-row {
      margin-top: 0px;
      margin-bottom: 50px;
    }
  }
  @media (max-width: 499px) {
    padding: 38px 12px;
    // height: auto;
    .settings-bg {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      .container {
        order: 2;
        .title {
          font-size: 24px;
          margin-top: 20px;
          margin-left: 5px;
        }
        .conditional {
          margin-top: 10px;
          margin-left: 5px;
          font-size: 11px;
        }
        .input-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          margin-top: 33px;
        }
        .input-label {
          font-size: 18px;
        }
      }
    }
    .button-row {
      margin-top: 0px;
      margin-bottom: 100px;
      .discard {
        margin-left: 10px;
      }
    }
    .kennelsetting-logo{
      bottom: -210px;
      right: 20px;
      width: 60px;
    }
  }
}
.settings-bg {
  // left: calc(50% - 500px);
  // top: calc(50% - 350px);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    .title {
      font-family: Audiowide;
      font-size: 35px;
      color: white;
      margin-top: 50px;
      margin-left: 5px;
    }
    .input-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      margin-top: 40px;
      .input-label {
        font-family: Arial;
        font-size: 24px;
        font-weight: 600;
        color: grey;
        margin-left: 5px;
      }
      input {
        outline: none;
        color: white;
        margin-top: 10px;
        width: 100%;
        height: 45px;
        border-radius: 4px;
        padding: 0 10px;
        box-sizing: border-box;
        border: 1px solid white;
        background-color: rgba(128, 128, 128, 0.658);
        &::placeholder {
          color: white;
        }
      }
    }
    .conditional {
      margin-top: 10px;
      margin-left: 5px;
      text-align: center;
      font-size: 16px;
      color: white;
    }

    .error {
      color: red;
    }
  }
  .kennel-image {
    // position: absolute;
    // top: 28%;
    // right: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: fit-content;
    }
    span {
      font-family: Arial;
      font-size: 24px;
      font-weight: 600;
      color: grey;
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }
}
.kennelsetting-logo {
  position: absolute;
  bottom: 40px;
  right: 50px;
  width: 70px;
}
.button-row {
  margin-top: 30px;
}
.save {
  color: white;
  font-size: 20px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: var(--main-green);
  padding: 10px 10px;
  width: 130px;
  height: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all ease 300ms;
  &:hover {
    cursor: pointer;
    background-color: #00ff0088;
  }
  &:disabled {
    background-color: black;
  }
}
.discard {
  margin-left: 40px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 2px solid var(--main-green);
  background-color: transparent;
  padding: 10px 10px;
  width: 130px;
  height: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all ease 300ms;
  &:hover {
    cursor: pointer;
    background-color: #00ff000a;
  }
  &:disabled {
    background-color: black;
  }
}
