.tournaments-title {
  margin-top: 40px;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 1rem;
  font-family: Audiowide;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 575px) {
    font-size: 36px;
  }
  .heading-tournament {
    color: var(--main-green) !important;
  }
  .title-info {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    font-family: Arial;
    // color: var(--main-green);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 575px) {
      text-align: center;
      font-size: 12px;
    }
  }
}
.entry-fee-clor {
  color: white;
}
.row-class-col {
  text-transform: uppercase;
}

.tournament-detail-sections {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  flex-direction: row !important;

  .panel-switch,
  .show-switch {
    margin: 0 10px;
  }
}

.panel-switch {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.btn-hover {
  transition: all 0.5s;
}
.btn-hover:hover {
  font-size: 16px;
}
.panel-switch-active {
  text-decoration: underline;
}

.points-row {
  min-width: 50% !important;
  max-width: 60% !important;
  margin: auto;
  @media (max-width: 1557px) {
    max-width: 75% !important;
  }
  @media (max-width: 991px) {
    max-width: 92% !important;
  }
}
.text-green {
  color: var(--main-green);
}
.mr-2 {
  margin-right: 10px;
  display: block;
}
.race-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;

  text-transform: uppercase !important;
  color: var(--main-green) !important;
}
.show-switch {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--main-green);
}

.onefour {
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 1600px) {
    width: 100%;
  }
}

.position-row {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;
  @media (max-width: 768px) {
    height: 110px;
  }
  @media (max-width: 575px) {
    height: 80px;
  }
  background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
  .position-index {
    color: yellow;
    // font-family: Arial;
    font-size: 36px;
    padding: 20px 40px;
    font-variant-numeric: ordinal;
    width: 115px;
    box-sizing: border-box;
    font-family: source-sans-pro;
    @media (max-width: 768px) {
      width: 95px;
      font-size: 24px;
    }
    @media (max-width: 575px) {
      width: 30px;
      padding: 20px 8px;
    }
  }
  .position-info {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-family: Audiowide;

    .img-container {
      height: 100%;
      max-width: 200px;
      overflow: hidden;
      @media (max-width: 575px) {
        max-width: 54px;
        img {
          max-height: 62px !important;
          scale: 154% !important;
          transform-origin: 26% 0% !important;
        }
      }
      img {
        max-height: 100px;
        scale: 200%;
        transform-origin: 20% 5%;
        @media (max-width: 768px) {
          max-height: 62px;
          scale: 248%;
          transform-origin: 15% 0%;
        }
      }
    }

    .tournaments-img-container {
      height: 100%;
      max-width: 200px;
      overflow: hidden;
      //   @media  (max-width:575px) {
      //  display: none;

      //   }
      img {
        max-height: 100px;
        scale: 200%;
        transform-origin: 20% 5%;
        @media (max-width: 768px) {
          max-height: 62px;
          scale: 248%;
          transform-origin: 15% 0%;
        }
      }
    }

    .detail {
      width: 100%;
      height: 100%;

      justify-content: center;
      flex-direction: column;
      .top {
        display: grid;
        grid-template-columns: 1fr 5fr 4.2fr;
        // display: flex;
        height: 50%;
        box-sizing: border-box;

        border-bottom: 1px solid grey;
        padding-bottom: 10px;
        position: relative;

        img {
          width: 40px;
          height: 40px;
          position: relative;
          top: -2px;
          left: -2px;
        }

        .top-1 {
          .name {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 20px;
            text-align: center;
            @media (min-width: 576px) and (max-width: 768px) {
              font-size: 14px;
            }
            @media (max-width: 575px) {
              font-size: 11px;
              align-items: center;
            }
          }

          display: flex;
          justify-content: center;
        }
        .top-2 {
          display: flex;
          margin-left: 30px;
          .extra {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 22px;
            @media (min-width: 576px) and (max-width: 991px) {
              font-size: 14px;
            }
            @media (max-width: 575px) {
              font-size: 11px;
              align-items: center;
            }
          }
          .time {
            display: flex;
            font-size: 22px;
            font-family: Arial;
            align-items: flex-end;
            color: yellow;
            margin-left: 10px;
            @media (max-width: 991px) {
              font-size: 14px;
            }
          }
        }
      }
    }
    .bottom {
      display: grid;
      grid-template-columns: 1fr 5fr 4.2fr;
      height: 50%;
      box-sizing: border-box;

      padding-top: 10px;
      border-top: 1px solid grey;
      font-family: Arial;
      font-weight: 600;
      .bot-1 {
        display: flex;
        justify-content: center;

        .bloodline {
          font-size: 20px;
          color: grey;
          text-transform: uppercase;

          @media (min-width: 576px) and (max-width: 1200px) {
            font-size: 14px;
          }

          @media (max-width: 575px) {
            font-size: 11px;
          }
        }
      }
      .bot-2 {
        display: flex;
        margin-left: 30px;
        .bloodline {
          font-size: 20px;
          color: grey;
          text-transform: uppercase;
          @media (min-width: 576px) and (max-width: 1200px) {
            font-size: 14px;
          }

          @media (max-width: 575px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}
