.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.register-form {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-row {
  display: flex;
  margin: 10px 0;
}

.acc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(63, 63, 63);
  color: white;
  width: 100%;

  .title {
    font-size: 2rem;
    margin: 20px 0;
  }

  .acc-info {
    .addr {
      margin: 10px 0;
    }
  }
  .balances {
    // margin-top: 20px;
    margin-left: 4px;
    display: flex;
    justify-content: space-between;

    .token-name {
      margin-left: 3px;
    }
  }
  .deposit-withdraw {
    background-color: rgb(117, 117, 117);
    border-radius: 5px;
    border: 2px solid black;
    padding: 20px;
    color: white !important;
    margin-top: 20px;
    .input-container {
      margin: 10px 0;
    }
  }

  .acc-history-wrapper {
    width: 100%;
    margin: 0 10px;

    @media (max-width: 550px) {
      overflow: auto;
    }
    .acc-history {
      max-width: 930px;
      width: auto;
      padding: 10px;
      margin: 0 auto;
      margin-top: 20px;
      background-color: rgb(110, 110, 110);
      border-radius: 5px;
      border: 2px solid black;

      .table-wrapper {
        overflow: auto;
        table {
          width: 100%;
          border-collapse: collapse;

          @media (max-width: 550px) {
            width: 600px;
          }
          .no-data {
            text-align: center;
          }
        }
      }
      td,
      th {
        padding: 5px;
      }
    }
  }
}
.token-info {
  margin-top: 20px;
  display: flex;
  font-size: 23px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.connectwallet {
  font-size: 20px;
  margin-top: 14px;
}
.divider {
  width: 2px;
  height: 250px;
  background-color: black;
  margin: 0 50px;
}
.success {
  color: rgb(0, 255, 0);
  font-size: 1.9rem;
}
.error {
  color: red;
  font-size: 1.2rem;
}
.left-arrow {
  width: 18px;
}
.prev-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sides-btn {
  font-family: Arial;
  font-weight: 600;
  min-width: 50px;
  background-color: transparent;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  color: #00b937e7;
}

.text-green {
  color: #00b937e7;
}

.text-golden {
  color: yellow;
}
