.racetrack {
  position: relative;
  margin: 0;
  background: black;
  height: 540px;
  border: 2px solid var(--main-green);
  border-radius: 5px;
  width: 70%;
  // margin-left: 50px;    
  overflow: hidden;
  @media (max-width:1200px) {
    width: 100%;
    // margin-top: 20px;
  }
  @media (max-width: 768px) {
    margin-left: 0px !important;
    height: 430px !important;
  }
  .startline {
    position: absolute;
    top: 0;
    // left: 15px;
    height: 100%;
    width: 178px;
    background-image: url("../assets/track-start.png");
    background-size: 100%;
    background-position: center;
    z-index: 1;
    // .line {
    //   width: 1px;
    //   height: 100%;
    //   background: var(--main-green);
    // }
    // div:last-child {
    //   position: absolute;
    //   top: 0;
    //   left: 10px;
    // }
    @media (max-width: 768px) {
      width: 138px !important;
    }
  }

  .dotted {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 2px;
    border-left: 2px dashed var(--main-green);

    span {
      position: absolute;
      color: var(--main-green);
      top: 95%;
      left: -50px;
    }
  }

  .finishline {
    background-image: url("../assets/track-finish.png");
    width: 187px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -6px;
    background-size: 100%;
  }
  .lanes {
    border-bottom: 1px solid grey;
    width: 100%;
    height: calc(12.5%);
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    color: white;
    .arrows-1 {
      position: absolute;
      z-index: 0;
    }
    .arrows-2 {
      position: absolute;
      z-index: 0;
    }
    .race-dog {
      position: relative;
      // left: -60px;
    }
    // img {
    //   position: relative;
    //   left: -60px;
    // }
  }
  .lanes:last-child {
    border-bottom: none;
  }
}

.arrows {
  background-image: url("../assets/track-arrows.png");
  height: 50px;

  position: absolute;
}
