:root {
  --historygrid: 2fr 4fr 4fr 3fr 5fr;
}

.history-row {
  color: white;
  &:active {
    color: white;
  }
  &:hover {
    color: white;
  }
  padding: 10px 25px;
  grid-template-columns: var(--historygrid);
  background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
  height: 80px;
  text-decoration: none;
  display: grid;
    flex-wrap: wrap;
    min-width: 700px;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
  .race-titles {
    font-family: Arial;
    font-size: 20px;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width:769px) { 
      font-size: 17px !important;
    }
  }
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 1px;
    @media screen and (max-width:769px) { 
      font-size: 17px !important;
    }
  }
  .name {
    font-family: Audiowide;
    font-size: 22px;
  }
  .position {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1px;
    width: 55px;
    height: 55px;
    border-radius: 27.5px;
    border: 2px solid gold;
    color: gold;
    font-size: 22px;
    box-sizing: border-box;
    font-family: source-sans-pro;
  }
  .distance {
    color: #00a128;
    font-size: 16px;
  }
  .date {
    color: yellow;
  }
}
.history-list {
  overflow-x: auto;
  
  .titles {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
   
    padding: 10px 25px;
    background: black;
    font-family: Arial;
    font-size: 16px;
    font-weight: 600;
    color: var(--main-green);
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 2fr 4fr 4fr 3fr 5fr;
    flex-wrap: wrap;
    min-width: 700px;
    gap: 20px;
    justify-content: space-between;
  }

  .history{
    min-width: 700px;
  }
}

.search-results {
  margin-left: 10px;
  display: flex;
  img {
    width: 30px;
  }
  input {
    margin-left: 10px;
    background: black;
    border: 3px solid #009c2f;
    color: var(--main-green);
    border-radius: 5px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    font-size: 24px;
    &:focus {
      outline: none;
    }
  }
}

.outcome-switch {
  .switch-msg {
    color: white;
    font-family: Arial;
    font-size: 16px;
    font-weight: 600;
  }
}
.MuiSwitch-thumb {
  color: var(--main-green);
}
.MuiSwitch-track {
  background-color: white !important;
}
