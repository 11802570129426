.leaderboard-main {
  background-color: black;
  color: white;
  width: 100%;

  .title-sec {
    padding: 40px;
    margin: 0 auto;
    color: #00a128;
    font-family: Audiowide;
    font-weight: 400;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // width: max-content;

    @media (max-width: 768px) {
      padding: 35px;
      font-size: 25px;
    }

    @media (max-width: 525px) {
      padding: 18px;
      font-size: 20px;
    }
    .selected {
      border-bottom: 2px solid var(--main-green);
    }
    div {
      cursor: pointer;
    }
  }

  .tab-main {
    background: rgb(54, 54, 54);
    padding: 25px;
    height: fit-content;

    @media (max-width: 768px) {
      padding: 15px;
    }

    .tab-sec {
      width: 100%;
      display: flex;
      gap: 30px;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        gap: 10px;
      }

      .tab-btn {
        padding: 8px 10px;
        background-color: transparent;
        color: var(--main-green);
        border: 0;
        border-bottom: 1px solid transparent;
        outline: none;
        font-size: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (max-width: 768px) {
          padding: 0px 8px;
          font-size: 16px;
        }

        img {
          @media (max-width: 768px) {
            width: 16px;
          }
        }
      }

      .down-arrow {
        margin-left: 10px;
        width: 25px;
        height: 25px;
      }

      // margin-top: 8px;
      .filter-btn {
        padding: 5px 10px;
        color: #ffffff;
        display: inline-block;
        font-size: 14px;
        font-weight: 800;
        border-radius: 2px;
      }

      .cancel-btn {
        background-color: rgb(227, 37, 69);
        margin-left: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .tab-btn.active {
        border-bottom: 1px solid var(--main-green);
      }
    }
  }

  .list {
    position: relative;
    overflow-x: auto;

    .titles.race-result-titles {
      min-width: 700px;
      padding: 10px 25px;
      background: black;
      font-family: Arial;
      font-size: 16px;
      font-weight: 600;
      color: var(--main-green);
      text-transform: uppercase;
      display: grid;
      grid-template-columns: var(--grid-layout);
      position: sticky;
      top: 0;
      z-index: 10;
      grid-template-columns: 4fr 3fr 3fr 3fr 3fr !important;

      @media (max-width: 1000px) {
        font-size: 14px;
        justify-content: space-between;
        align-items: center;
      }

      div {
        min-width: auto !important;
        align-items: center;
        display: flex;
        justify-content: center;

        @media (max-width: 1024px) {
          justify-content: center;
        }

        &:nth-child(1) {
          display: block;
        }

        &:nth-child(2) {
          justify-content: center;
          min-width: 100px !important;
        }
      }
    }

    .race-result-row {
      height: calc(100% - 20px);
      background: black;
      color: white;
      min-width: 751px !important;

      @media (min-width: 1000px) {
        max-height: calc(100vh - 23rem);
        min-height: calc(100vh - 23rem);
      }

      .race-row {
        padding: 10px 25px;
        display: grid;
        background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
        height: 80px;
        text-decoration: none;
        grid-template-columns: 4fr 3fr 3fr 3fr 3fr !important;

        .race-titles {
          font-family: Audiowide;
          font-size: 20px;
          color: white;
          min-width: 200px;
          //max-width: 200px;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          cursor: pointer;

          span {
            &:last-child {
              font-family: Arial;
              // color: var(--main-green);
            }
          }
        }
      }

      .entry-fee {
        color: rgb(255, 251, 0);
      }

      .grid-places {
        color: white;
      }

      div {
        min-width: 120px;
      }

      .grid-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Arial;
        font-weight: 600;
        text-decoration: none;
        letter-spacing: 1px;
      }

      .prize-pool {
        color: #00a128;
      }

      .distance {
        color: white;
      }
    }
  }

  .races {
    background-color: white;
  }
}
