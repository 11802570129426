.home_contain {
  width: 100%;
  height: auto;
}

.homebg {
  // background-image: url("../../assets/home-bg.png");
  background-color: rgb(0, 0, 0);
  // background-image: url(/static/media/home-bg.7b9f991….png);
  background-size: 100%;
  color: rgb(255, 255, 255);
  font-family: Audiowide;
  min-height: 100vh;
  height: 100dvh;
  position: relative;
  width: 100%;
  background-position: 50% center;
  background-repeat: no-repeat;
  overflow: hidden;
 
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: top;
  // background-color: black;
  // width: 100%;
  // height: 100vh;
  // position: relative;
  // font-family: Audiowide;
  // color: white;

/* styles.css */


.vidiobg {
  position: absolute;
  top: 53px;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important; 
}
.vidiobg video{
  object-fit: cover;
}
  

  @media (min-width: 2000px) {
    background-size: contain !important;
  }

  @media (max-width: 1450px) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    background-color: black;
    width: 100%;
    // min-height: 100vh;
    position: relative;
    font-family: Audiowide;
    color: white;
  }

  @media (max-width: 991px) {
    height: 100%;
  }

  .welcome {
    position: absolute;
    top: 0px;
    padding-top: 167px;
    left: 0%;
    height: 100%;
    width: 100%;
    place-content: center;
    z-index: 999;
    font-size: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-weight: 900;
    background-color: #00000052;
   

    // transform: translate(-50%, -50%);

    // @media (max-width: 1200px) {
    //   width: 100%;
    //   bottom: 40px;
    //   left: 50%;
    //   justify-content: center;
    // }

    @media (max-width: 992px) {
      font-size: 30px;
    }

    @media (max-width: 768px) {
      // top: 88% !important;
      font-size: 31px;
    }

    span {
      margin: 0 10px;
    }
    @media (max-width: 768px) {
      span {
        margin: 0 5px;
      }
    }

    .arrow {
      margin-left: 10px;
      height: 30px;
    }

    .subtext {
      width: 600px;

      h6 {
        text-align: center;
        font-size: 24px;
        line-height: 30px;
        margin: 18px 0 0 0;
      }
    }
  }
  .overlayBox{
    padding: 30px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  @media (max-width: 800px) {
    .subtext {
      width: 80% !important;
      h6 {
        font-size: 21px !important;
      }
    }
  }

  .info-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 100px;
    left: 1250px;
    font-size: 20px;

    .top {
      margin-top: 5px;
      margin-bottom: 25px;
    }
  }

  .greet-msg {
    text-align: center;
    @media (max-width: 320px) {
      margin-left: 10px;
    }
    .dark-green {
      @media (max-width: 320px) {
        margin-left: 0;
      }
    }
  }
  @media (min-width: 2000px) {
    .box-flex {
      top: 39% !important;
      left: 14% !important;
    }

    .main-box {
      height: 60%;
      display: flex;
      justify-content: end;
      align-items: center;

      .boxes-container {
        margin-top: 0px !important;
      }
    }
  }

  @media (max-width: 768px) {
    .main-box {
      .boxes-container {
        margin-top: 20px !important;
      }
    }
  }

  .boxes-container {
    float: right;
    margin-top: 30px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    @media (min-width: 1201px) and (max-width: 1440px) {
      margin-top: 84px;
    }

    @media (min-width: 991px) and (max-width: 1200px) {
      margin-top: 130px;
    }

    @media (max-width: 991px) {
      margin-top: 0;

      // padding: 59px 0 393px 0;
    }

    @media (max-width: 768px) {
      margin-top: 0 !important;
      // padding: 226px 0 204px 0 !important;
    }

    .box {
      text-decoration: none;
      width: 300px;
      height: 65px;
      font-size: 24px;
      background: #1f1f1f;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #2bb900;
      border-radius: 14px;
      margin-top: 20px;

      @media (max-width: 768px) {
        margin-top: 5px;
      }

      @media (max-width: 400px) {
        font-size: 18px;
      }
    }
  }

  .box-flex {
    text-decoration: none;
    position: absolute;
    width: fit-content;
    min-width: 400px;
    max-width: 400px;
    height: fit-content;
    min-height: 65px;
    font-size: 24px;
    background: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #2bb900;
    border-radius: 14px;

    @media (min-width: 1277px) and (max-width: 1440px) {
      min-width: 357px !important;
      max-width: 357px !important;
      min-height: 65px !important;
      font-size: 18px !important;
      top: 320px !important;
      left: 27px !important;
    }

    @media (min-width: 991px) and (max-width: 1276px) {
      top: 407px !important;
      left: 119px !important;
      min-width: 357px !important;
      max-width: 357px !important;
      min-height: 65px !important;
      font-size: 18px !important;
      transform: rotate(353deg) !important;
    }

    @media (max-width: 991px) {
      top: 387px !important;
      left: 125px !important;
      transform: rotate(355deg) !important;
      min-width: 357px !important;
      max-width: 357px !important;
      min-height: 65px !important;
      font-size: 18px !important;
    }

    @media (max-width: 768px) {
      top: 15px !important;
      left: 0 !important;
      transform: rotate(0deg) !important;
      min-width: auto;
    }

    @media (max-width: 475px) {
      font-size: 14px !important;
      min-height: 40px !important;
      margin: 0px 10px !important;
      max-width: 283px !important;
      min-width: 233px !important;
      position: absolute !important;
      left: 47% !important;
      transform: translateX(-50%) !important;
    }
  }

  a {
    text-decoration: none;
    color: white;
  }

  a:visited {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: none;
    color: white;
  }

  a:focus {
    text-decoration: none;
    color: white;
  }

  a:active {
    text-decoration: none;
    color: white;
  }
}

.email-ref {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  h6 {
    font-size: 18px;
    margin: 0;
  }
  @media (max-width: 992px) {
    flex-direction: column;
  }

  .connect-btn {
    width: 140px;
    height: 40px;
    padding: 0.25em;
    align-items: center;
    background-color: #1976d2;
    font-size: 14px;
    padding: 5px;
    color: white;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    border: none;
    outline: none;
  }

  .main-box {
    height: auto;
    // display: flex;
    // justify-content: end;
    // align-items: center;

    .boxes-container {
      display: flex;
      gap: 10px;
      margin-top: 0px !important;
    }
  }

  @media (max-width: 768px) {
    .main-box {
      position: absolute;
      bottom: 135px;

      .boxes-container {
        padding: 0px !important;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  .boxes-container {
    float: right;
    margin-top: 143px;

    @media (min-width: 1201px) and (max-width: 1440px) {
      margin-top: 84px;
    }

    @media (min-width: 991px) and (max-width: 1200px) {
      margin-top: 130px;
    }

    @media (max-width: 991px) {
      margin-top: 0;

      padding: 59px 0 393px 0;
    }

    @media (max-width: 768px) {
      margin-top: 0 !important;
      // padding: 226px 0 204px 0 !important;
    }

    .box {
      text-decoration: none;
      width: 300px;
      height: 65px;
      font-size: 24px;
      background: #1f1f1f;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #2bb900;
      border-radius: 14px;
      margin-top: 20px;

      @media (max-width: 768px) {
        margin-top: 5px;
      }

      @media (max-width: 400px) {
        font-size: 18px;
      }
    }
  }

  a {
    text-decoration: none;
    color: white;
  }

  a:visited {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: none;
    color: white;
  }

  a:focus {
    text-decoration: none;
    color: white;
  }

  a:active {
    text-decoration: none;
    color: white;
  }
}

.blog_contain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
  background-color: #f7f8f9;

  .works {
    text-align: center;
    background-color: #dcfce7;
    text-transform: capitalize;
    width: max-content;
    margin: 0 auto;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    color: #22c55e;
  }

  h6 {
    text-align: center;
    font-size: 45px;
    margin: 20px 0 5px 0 !important;
    color: #2a3342;
  }
  span {
    text-align: center;
    font-size: 20px;
    color: #556987;
    margin: 5px 0;
    font-weight: 500;
  }

  .card_contain {
    margin: 50px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 0 20px;
    font-family: Audiowide;

    // padding: 0 100px;
    .card {
      background-color: #fff;
      border-radius: 17px;
      box-shadow: 0px 0px 0px grey;
      position: relative;
      padding: 50px 20px 20px 20px;
      max-width: 350px;
      .index {
        position: absolute;
        top: -35px;
        left: 45%;
        background-color: #22c55e;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: grid;
        place-content: center;
        color: #fff;
        font-weight: bolder;
      }
      h5 {
        font-size: 20px;
        margin: 0;
        color: #2a3342;
      }
      ul {
        padding: 0 19px;
        li {
          color: #556987;
          padding: 5px 0;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .card_contain {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 600px) {
    .card_contain {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
@media screen and (max-width: 1000px) {
  .homebg {
    // background-size: 80%;
    background-repeat: no-repeat;
    // background-position: 40% -50px;
    background-color: black;
    width: 100%;
    position: relative;
    font-family: Audiowide;
    color: white;
   
   

    .flex-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      height: 100%;
      padding-top: 30vh;
    }

    .flex-section {
      // border:2px solid red !important;
      width: 100%;
    }

    .flex-section.upper-section {
      padding-top: 5%;
      height: 30vh;
    }

    .boxes-container {
      display: flex;
      align-items: center;
    }

    .box {
      font-size: 100%;
      position: relative;
      top: 0px !important;
      left: 0px;
      margin: 15px auto;
    }
  }

  .welcome {
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0.99em;
    
   

    img {
      width: 0.75em;
    }
  }

  @media (max-width: 600px) {
    .homebg .welcome {
      height: 707px;
    }
  }
}

@media screen and (max-width: 991px) {
  .homebg {
    background-size: cover;
    height: 100dvh;
  }
}

@media screen and (max-width: 768px) {
  .homebg {
    background-position: 42% 50%;
    height: 100vh !important;
    position: relative;
  }
}

@media screen and (max-width: 400px) {
  .box {
    max-width: 200px !important;
  }
}



