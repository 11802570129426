.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  padding: 10px 10px;
  overflow: auto;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
}
