.rental-temp {
  background-image: url("./temp.png");
  width: 80%;
  height: 80%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
}

@media screen and (max-width: 1000px){
  .rental-temp {
    background-size: contain;
  }
}
@media screen and (max-width: 768px){
  .rental-temp {
    width: 486px;
    height: 490px;
  }
}
@media screen and (max-width: 576px){
  .rental-temp {
    width: 376px;
    height: 370px;
  }
}
@media screen and (max-width: 425px){
  .rental-temp {
    width: 296px;
    height: 290px;
  }
}
