.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width:1000px) { 
    margin-top: 20px;
  }
  @media (max-width:400px) { 
    margin-top: 0px !important;
  }
}
.content-padding {
  position: relative;
  padding-top: 87px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  
  // @media (min-width:768px) and (max-width:1000px) { 
  //   min-height: 100vh !important;
  // }

  
}
@media screen and (max-width: 1505px) {
  .content-padding {
    padding-top: 77px !important;
  }
}
.noscroll {
  overflow: hidden;
}


@media screen and (max-width: 1000px){
  .content-padding{
    position:relative;
    padding-top:59px !important;
    display:block;
  }
}