.bg {
  height: calc(100vh - 100px);
  width: 750px;
  background: linear-gradient(
    to bottom,
    rgb(88, 88, 88) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: relative;
  // left: calc(50% - 375px);
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid grey;
  justify-content: space-between;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    width: auto;
    height: 500px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;

    cursor: pointer;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 490px;
    padding: 20px;

    @media (max-width: 768px) {
      width: auto;
    }

    .title {
      font-family: Audiowide;
      font-size: 36px;
      color: white;
      margin-top: 50px;

      @media (max-width: 575px) {
        font-size: 25px;
        margin-top: 30px;
      }
      @media (max-width: 480px) {
        font-size: 19px;
        margin-top: 30px;
      }
    }

    .token {
      margin-top: 50px;
      max-width: 100%;

      @media (max-width: 575px) {
        margin-top: 25px;
      }
    }

    .input-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      margin-top: 40px;

      .input-label {
        font-family: Arial;
        font-size: 16px;
        font-weight: 600;
        color: white;
        margin-left: 5px;
      }

      input {
        outline: none;
        color: white;

        margin-top: 5px;
        width: 100%;
        height: 45px;
        border-radius: 4px;
        padding: 0 10px;
        box-sizing: border-box;
        border: 1px solid white;
        background-color: rgba(128, 128, 128, 0.658);
      }
    }

    .conditional {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      color: grey;
    }

    .extra-notes {
      margin-top: 20px;
      color: white;

      .point {
        margin: 10px 0;
      }
    }

    .error {
      color: red;
    }
  }

  .kennel-logo {
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 70px;
  }
  @media (max-width: 575px) {
    .kennel-logo {
      position: absolute;
      left: auto;
      width: 50px;
    }
    @media (max-width: 375px) {
      .kennel-logo {
        right: 15px;
        bottom: 10px;
        left: auto;
      }
    }
  }
}

.save {
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: var(--main-green);
  padding: 10px 10px;
  width: 110px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all ease 300ms;

  &:hover {
    cursor: pointer;
    background-color: #00ff0088;
  }
}

.Resume_EXP_Gain_model {
  border: 1px solid black;
  background-color: #000000cf;
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  place-items: center;
  top: 0;

  .model {
    width: max-content;
    height: max-content;
    background: linear-gradient(
      to bottom,
      rgb(88, 88, 88) 0%,
      rgb(0, 0, 0) 100%
    );
    padding: 38px;
    border-radius: 8px;
    border: 1px solid #fff;

    p {
      color: #fff;
      font-size: 20px;
      font-weight: 800;
    }
    .btn {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        width: 100%;
        background: #13bc41;
        border: none;
        height: 50px;
        padding: 10px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 700;
        color: #FFF;
      }
    }
  }
}
