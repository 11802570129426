.breed-temp {
  background-image: url("./temp.png");
  width: 80%;
  height: 80%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
}

@media screen and (max-width: 1000px){
  .breed-temp{
    background-size: contain;
    height:50%;
  }
}
