.menu {
  position: sticky;
  top: 0px;
  width: 300px;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;

  background: linear-gradient(to bottom, #ffffff -500%, #000000 100%);
  border-right: 1px solid grey;
  @media (max-width:425px) {
    // position: static;
    flex-direction: column !important;
  }
  .option {
    font-family: Audiowide;

    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient(to bottom, #ffffff -300%, #000000 100%);
    cursor: pointer;
    &:hover {
      background: #000000;
      color: var(--main-green);
    }
  }
  .logo {
    position: absolute;
    height: 80px;

    top: 80%;
    left: calc(50% - 40px);
  }
}


@media (min-width:1001px) and (max-width: 1200px){
  .menu{
    min-width: 200px;
    max-width: 200px;
    .option{
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1000px){
  .menu{
    display:flex;
    flex-direction: row;
    height:fit-content;
    width:100vw;
    min-width:10px !important;
    .option{
      height: 50px;
      flex: 1;
    }

    .logo{
      width: 1em;
      height: 1em;
      margin: auto 10px;
      position: relative;
      top:auto;
      left:auto;
    }
  }
}
@media screen and (max-width: 425px){
  .menu{
    position: sticky;
    top: 0;
    .option{
      font-size: 9px ;
    }
  }

}
@media (min-width:576px) and (max-width: 768px){
  .menu{
    .option{
      font-size: 12px;
    }
  }
  
}
